import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Suspense } from "react";
import LoadingFullPage, { LoadingFullPageProps } from "../Loadings/LoadingFullPage";

interface SuspenseDefaultProps {
  element: ReactJSXElement;
}

export default function SuspenseDefault({
  element,
  showLogo,
}: SuspenseDefaultProps & LoadingFullPageProps) {
  return <Suspense fallback={<LoadingFullPage showLogo={showLogo} />}>{element}</Suspense>;
}
