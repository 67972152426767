import './New.css';

import { Link, useHistory } from 'react-router-dom';

import { Button, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import ApiCourses from '../../../api/Course';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useQuery from '../../../hooks/useQuery';

function NewCourse() {
  const [nameCourse, setNameCourse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const saveCourse = async event => {
    event.preventDefault();
    let type = query.get('type');

    setIsLoading(true);

    try {
      const { data: newCourse } = await ApiCourses.store(nameCourse);

      if (newCourse) {
        if (type && type === 'subscription') {
          history.push(`/courses/${newCourse.id}/course-manager/price/subscription`);
          return;
        }
        history.push(`/courses/${newCourse.id}/course-manager/course`);
      }
    } catch (error) {
      Toast(ErrorResponse(error, history), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="row h-70 d-flex justify-content-center align-items-center">
      <div className="col-xl-8 col-md-8 col-lg-8">
        <form onSubmit={saveCourse}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Heading as="h4" fontSize="1.5rem" fontWeight="semibold">
                Nome do Produto
              </Heading>
            </div>
          </div>
          <input
            type="text"
            name="name"
            placeholder="Digite o nome do seu produto"
            value={nameCourse}
            onChange={event => setNameCourse(event.target.value)}
            autoComplete="off"
            className="form-control mt-3 input-new-course mb-2"
            required
            minLength="2"
            maxLength="90"
          />
          <div className="d-flex justify-content-end mt-4">
            <Link
              to="/courses"
              className="btn btn-light my-0 rounded-lg"
              style={{ fontWeight: 600, fontSize: '1rem', marginRight: '1.25rem' }}
            >
              Cancelar
            </Link>
            <div>
              <Button
                type="submit"
                isLoading={isLoading}
                colorScheme="primary"
                color="secondary.500"
              >
                Criar Produto
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewCourse;
