import { Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { Heading } from '../../../../../components/Heading';
import { useCourse } from '../../../../../contexts/CourseContext';
import { PRODUCTS_FILTER } from '../../../../../helpers/LocalStorageHelper';

export default function HeaderCourseManager() {
  const { course } = useCourse();
  const { id } = useParams<{ id: string }>();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const previousProductsFilters = localStorage.getItem(PRODUCTS_FILTER);

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      bg="primary.500"
      py={3}
      position="relative"
    >
      <Button
        as={Link}
        to={`/courses?${previousProductsFilters}`}
        leftIcon={<MdChevronLeft size="1.5rem" />}
        color="secondary.500"
        colorScheme="primary"
        _hover={{ bgColor: 'transparent' }}
        bgColor="transparent"
        title="Voltar para a página de produtos"
        fontSize="sm"
      >
        {isMobile ? '' : 'Voltar'}
      </Button>

      <Heading
        as="h1"
        fontSize={{ base: 'md', xl: 'xl' }}
        fontWeight="bold"
        color="secondary.500"
        marginLeft="auto"
        marginRight="auto"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
      >
        {course?.name}
      </Heading>

      <Button
        as={Link}
        to={`/product/${id}`}
        rightIcon={<MdChevronRight size="1.5rem" />}
        color="secondary.500"
        colorScheme="primary"
        _hover={{ bgColor: 'transparent' }}
        bgColor="transparent"
        title="Voltar para a página de produtos"
        fontSize="sm"
      >
        Visualizar Página de Venda
      </Button>
    </Flex>
  );
}
