import { ITheme } from '../../contexts/ThemeContext';
import styles from './styles.module.css';

interface Props {
  title: string;
  themeMarketplace: ITheme;
  className?: string;
}

function DefaultImageProduct({ title = 'Sem título', themeMarketplace, className = '' }: Props) {
  return (
    <div
      className={`${styles.defaultImageProduct} ${className}`}
      style={{
        background: `${themeMarketplace.primaryColor}`,
      }}
    >
      <div>
        <span style={{ color: themeMarketplace.textColor }}>
          {title.substring(0, 1).toUpperCase()}
        </span>
      </div>
    </div>
  );
}

export default DefaultImageProduct;
