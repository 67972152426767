import { Center, Heading } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Paginate from '../../../../../../components/Paginate';
import useFetch from '../../../../../../hooks/useFetch';
import hasFilterParams from '../../../../../../utils/hasFilterParams';
import Loading from '../components/Loading';
import RecurrencePaymentHistoryHeader from './RecurrencePaymentHistoryHeader';
import PaymentTable from './components/PaymentTable';
import MobilePaymentList from './components/MobilePaymentList';

export interface Payment {
  transactionId: string;
  status: string;
  fullName: string;
  paymentMethod: string;
  createdAt: Date;
}

interface PaymentHistoryResponse {
  paymentHistory: Payment[];
  page: number;
  per_page: number;
  total: number;
}

export default function RecurrencePaymentHistory() {
  const location = useLocation();
  const history = useHistory();
  const { recurrenceId } = useParams<{ recurrenceId: string }>();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<PaymentHistoryResponse>
  >({
    method: 'get',
    url: `/recurrence/${recurrenceId}/payment-history?${urlSearchParams.toString()}`,
    autoFetch: true,
    authenticated: true,
  });

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  const paymentHistoryData = response?.data?.paymentHistory || [];
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !paymentHistoryData?.length && isFiltering;
  const isEmpty = !isLoading && !paymentHistoryData?.length && !isFiltering;
  const isPaymentHistoryVisible = !isLoading && !!paymentHistoryData?.length;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const isPaginationVisible = !isLoading && pageCount > 1;
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  const subscriberName =
    (location.state as { subscriberName: string }).subscriberName ||
    paymentHistoryData?.[0]?.fullName;

  return (
    <>
      <RecurrencePaymentHistoryHeader subscriberName={subscriberName} isEmpty={isEmpty} />

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isPaymentHistoryVisible && (
        <>
          <PaymentTable
            display={{ base: 'none', xl: 'block' }}
            paymentHistory={paymentHistoryData}
          />

          <MobilePaymentList
            display={{ base: 'block', xl: 'none' }}
            mt={2.5}
            paymentHistory={paymentHistoryData}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          mt={{ base: 8, xl: 4 }}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
