import { Box, Center, Heading, Progress } from '@chakra-ui/react';

export type LoadingFullPageProps = {
  showLogo?: boolean;
};

export default function LoadingFullPage({ showLogo = false }: LoadingFullPageProps) {
  return (
    <Box width="full" height="full">
      <Progress width="full" position="absolute" colorScheme="primary" isIndeterminate />
      <Center height="full" visibility={showLogo ? 'visible' : 'hidden'}>
        <Heading>Carregando...</Heading>
      </Center>
    </Box>
  );
}
