import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BiDotsHorizontal } from 'react-icons/bi';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import CourseApi from '../../api/Course';
import { useTheme } from '../../contexts/ThemeContext';
import ErrorResponse from '../../helpers/ErrorResponse';
import DefaultImageProduct from '../DefaultImageProduct';
import { Heading } from '../Heading';
import Toast from '../Toast';
import './CoursesCard.css';

import {
  AspectRatio,
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

interface Props {
  title: string;
  image: string;
  status: StatusType;
  id: number;
  onDelete: (id: number) => void;
}

const fontSize = {
  base: 'x-small',
  sm: 'sm',
};

export function setClassesStatus(status: StatusType) {
  const STATUS = {
    ATIVO: 'h7 text-success pt-1',
    INATIVO: 'h7 text-secondary pt-1',
    EM_EDICAO: 'h7 text-warning pt-1',
    BLOQUEADO: 'h7 text-danger pt-1',
    NAO_LISTADO: 'h7 text-dark pt-1',
  };

  return STATUS[status] || 'h6 text-warning pt-1';
}

export function formatNameStatus(status: StatusType) {
  const STATUS_VALUE = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  };

  return STATUS_VALUE[status] || '...';
}

const CoursesCard = ({ title, image, status, id, onDelete }: Props) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModal2, setIsShowModal2] = useState(false);

  const handleViewModal = () => setIsShowModal(true);
  const [disable, setDisable] = useState(false);
  const { themeMarketplace } = useTheme();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  async function deletarCurso() {
    try {
      setDisable(true);
      await CourseApi.delete(id);
      setIsShowModal(false);
      setIsShowModal2(true);
      setDisable(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function updatePage() {
    onDelete(id);
    setIsShowModal2(false);
  }

  return (
    <>
      <Box
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        bg="transparent"
        maxWidth="256px"
        borderRadius="10px"
      >
        <Link to={`/courses/${id}/course-manager/course?${urlSearchParams.toString()}`}>
          <Flex direction="column" align="center" justify="center">
            <Box overflow="hidden" width="100%" borderRadius="10px 10px 0 0">
              <AspectRatio maxWidth={{ base: '244px', sm: 'unset' }} ratio={1}>
                {image ? (
                  <Image src={image} alt="Imagem do curso" objectFit="cover" />
                ) : (
                  <DefaultImageProduct title={title} themeMarketplace={themeMarketplace} />
                )}
              </AspectRatio>
            </Box>
          </Flex>
        </Link>

        <HStack justifyContent="space-between" alignItems="center" p={2} mx={1}>
          <Heading noOfLines={2} fontSize={fontSize} color="#202123">
            {title}
          </Heading>
          <Menu>
            <MenuButton
              style={{ boxShadow: 'none', outline: 'none' }}
              bgColor="#20212312"
              borderRadius={5}
              p={1}
            >
              <BiDotsHorizontal size="20px" color="#20212380" />
            </MenuButton>
            <MenuList>
              <Link to={`/courses/${id}/course-manager/course`}>
                <MenuItem style={{ boxShadow: 'none', outline: 'none' }}>Editar</MenuItem>
              </Link>
              <Link to={`/students-report/${id}/course?page=1`}>
                <MenuItem style={{ boxShadow: 'none', outline: 'none' }}>Lista de Alunos</MenuItem>
              </Link>
              <Link to={`/reporting/${id}/course?page=1`}>
                <MenuItem style={{ boxShadow: 'none', outline: 'none' }}>
                  Relatório Questões
                </MenuItem>
              </Link>
              <Link to={`/courses/${id}/correction-task?page=1`}>
                <MenuItem style={{ boxShadow: 'none', outline: 'none' }}>Corrigir tarefas</MenuItem>
              </Link>
              <MenuItem
                onClick={handleViewModal}
                style={{ boxShadow: 'none', outline: 'none' }}
                color="red"
              >
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <Box p={2} mx={1}>
          <Heading fontSize={fontSize} color="#20212350">
            Status:{' '}
            <Text
              fontSize={fontSize}
              className={`${setClassesStatus(status)}`}
              fontWeight={500}
              as="line"
            >
              {formatNameStatus(status)}
            </Text>
          </Heading>
        </Box>
      </Box>

      {/* MODAIS */}
      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex flex-column align-items-center justify-content-center mb-1 px-2">
            <FaExclamationCircle size="4rem" color="#EB7129" />
            <h3 className="text-center mt-2">Excluir o curso?</h3>
            <p className="text-center mt-2">
              Após a exclusão, todos os módulos e arquivos referentes a esse curso também serão
              excluídos.
            </p>
          </div>
          <div className="row d-flex mb-1 mt-4 px-2" style={{ gap: '1rem' }}>
            <button
              onClick={() => setIsShowModal(false)}
              disabled={disable}
              className="btn btn-primary col d-flex justify-content-center"
            >
              Cancelar
            </button>
            <button
              onClick={deletarCurso}
              disabled={disable}
              className="btn btn-outline-danger col d-flex justify-content-center"
              style={{
                backgroundColor: 'transparent',
              }}
            >
              Excluir curso
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="sm"
        show={isShowModal2}
        onHide={updatePage}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex justify-content-center mb-1 py-4">
            <FaCheckCircle
              className="d-flex justify-content-center mb-2"
              style={{ color: 'green', height: '50px', width: '50px' }}
            />

            <h3 className="text-center">Curso deletado com sucesso !</h3>
          </div>
          <div className="d-flex justify-content-center mb-4 ">
            <button
              onClick={updatePage}
              className="btn btn-light"
              style={{
                backgroundColor: '#EB7129',
                color: 'white',
                alignItems: 'center',
              }}
            >
              Retornar a tela de cursos
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CoursesCard;
