import './CoursesCardList.css';

import { Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BiDotsHorizontal } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import CourseApi from '../../api/Course';
import { useTheme } from '../../contexts/ThemeContext';
import ErrorResponse from '../../helpers/ErrorResponse';
import DefaultImageProductList from '../DefaultImageProductList';
import { Heading } from '../Heading';
import Toast from '../Toast';

type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

interface Props {
  title: string;
  image: string;
  status: StatusType;
  id: number;
  onDelete: (id: number) => void;
}

function setClassesStatus(status: StatusType) {
  const STATUS = {
    ATIVO: 'h7 text-success pt-1',
    INATIVO: 'h7 text-secondary pt-1',
    EM_EDICAO: 'h7 text-warning pt-1',
    BLOQUEADO: 'h7 text-danger pt-1',
    NAO_LISTADO: 'h7 text-dark pt-1',
  };

  return STATUS[status] || 'h6 text-warning pt-1';
}

function formatNameStatus(status: StatusType) {
  const STATUS_VALUE = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  };

  return STATUS_VALUE[status] || '...';
}

const CoursesCardList = ({ title, image, status, id, onDelete }: Props) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModal2, setIsShowModal2] = useState(false);
  const [disable, setDisable] = useState(false);

  const { themeMarketplace } = useTheme();
  const history = useHistory();

  const handleViewModal = () => setIsShowModal(true);

  async function deletarCurso() {
    try {
      setDisable(true);

      await CourseApi.delete(id);

      setIsShowModal(false);

      setIsShowModal2(true);

      setDisable(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function updatePage() {
    onDelete(id);
    setIsShowModal2(false);
  }

  function redirectToEditPage() {
    history.push(`/courses/${id}/course-manager/course`);
  }

  return (
    <>
      <Flex
        my={5}
        borderRadius="lg"
        boxShadow="0px 0px 2.8298280239105225px 0px #00000040"
        overflow="hidden"
      >
        <Flex onClick={redirectToEditPage} cursor="pointer">
          {image ? (
            <Image
              src={image}
              alt="Imagem do curso"
              objectFit="cover"
              borderRadius="lg"
              boxSize={{ base: '75px', md: '106px' }}
            />
          ) : (
            <DefaultImageProductList title={title} themeMarketplace={themeMarketplace} />
          )}
        </Flex>

        <Flex
          flex={{ base: 2.725, md: 5, lg: 4.5, xl: 8.5 }}
          direction="column"
          align="flex-start"
          justify="space-between"
          gap={3}
          py={{ base: 2, lg: '1.125rem' }}
          onClick={redirectToEditPage}
          cursor="pointer"
          pl={{ base: 4, lg: 6 }}
        >
          <Text
            fontSize={{ base: 'smaller', lg: '18px' }}
            color={'#202123'}
            fontWeight={600}
            noOfLines={2}
          >
            {title}
          </Text>
          <Heading fontSize={{ base: '11px', lg: 'sm' }} fontWeight={600} color="#20212350">
            Status:{' '}
            <Text className={`${setClassesStatus(status)}`} as="span">
              {formatNameStatus(status)}
            </Text>
          </Heading>
        </Flex>

        <Flex justify="flex-start" h="100%" p={{ base: 2, lg: '1.125rem' }}>
          <Menu>
            <MenuButton
              w="27px"
              h="24px"
              style={{ boxShadow: 'none', outline: 'none' }}
              bgColor="#20212312"
              borderRadius={5}
              p={1}
              m={1}
            >
              <BiDotsHorizontal size="20px" color="#20212380" />
            </MenuButton>
            <MenuList>
              <Link to={`/courses/${id}/course-manager/course`}>
                <MenuItem
                  style={{ boxShadow: 'none', outline: 'none' }}
                  maxH={{ base: '80px', md: '240px', lg: '240px' }}
                >
                  Editar
                </MenuItem>
              </Link>
              <Link to={`/students-report/${id}/course?page=1`}>
                <MenuItem
                  style={{ boxShadow: 'none', outline: 'none' }}
                  maxH={{ base: '80px', md: '240px', lg: '240px' }}
                >
                  Lista de Alunos
                </MenuItem>
              </Link>
              <Link to={`/reporting/${id}/course`}>
                <MenuItem
                  style={{ boxShadow: 'none', outline: 'none' }}
                  maxH={{ base: '80px', md: '240px', lg: '240px' }}
                >
                  Relatório Questões
                </MenuItem>
              </Link>
              <Link to={`/courses/${id}/correction-task?page=1`}>
                <MenuItem
                  style={{ boxShadow: 'none', outline: 'none' }}
                  maxH={{ base: '80px', md: '240px', lg: '240px' }}
                >
                  Corrigir tarefas
                </MenuItem>
              </Link>
              <MenuItem
                onClick={handleViewModal}
                style={{ boxShadow: 'none', outline: 'none' }}
                color="red"
              >
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {/* MODAIS */}

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex flex-column align-items-center justify-content-center mb-1 px-2">
            <FaExclamationCircle size="4rem" color="#EB7129" />
            <h3 className="text-center mt-2">Excluir o curso?</h3>
            <p className="text-center mt-2">
              Após a exclusão, todos os módulos e arquivos referentes a esse curso também serão
              excluídos.
            </p>
          </div>
          <div className="row d-flex mb-1 mt-4 px-2" style={{ gap: '1rem' }}>
            <button
              onClick={() => setIsShowModal(false)}
              disabled={disable}
              className="btn btn-primary col d-flex justify-content-center"
            >
              Cancelar
            </button>
            <button
              onClick={deletarCurso}
              disabled={disable}
              className="btn btn-outline-danger col d-flex justify-content-center"
              style={{
                backgroundColor: 'transparent',
              }}
            >
              Excluir curso
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="sm"
        show={isShowModal2}
        onHide={updatePage}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex justify-content-center mb-1 py-4">
            <FaCheckCircle
              className="d-flex justify-content-center mb-2"
              style={{ color: 'green', height: '50px', width: '50px' }}
            />

            <h3 className="text-center">Curso deletado com sucesso !</h3>
          </div>
          <div className="d-flex justify-content-center mb-4 ">
            <button
              onClick={updatePage}
              className="btn btn-light"
              style={{
                backgroundColor: '#EB7129',
                color: 'white',
                alignItems: 'center',
              }}
            >
              Retornar a tela de cursos
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CoursesCardList;
