import { Flex, Heading } from '@chakra-ui/react';
import { ITheme } from '../../contexts/ThemeContext';

interface Props {
  title: string;
  themeMarketplace: ITheme;
}

function DefaultImageProductList({ title = 'Sem título', themeMarketplace }: Props) {
  return (
    <Flex
      align="center"
      justify="center"
      bgColor={themeMarketplace.primaryColor}
      boxSize={{ base: '75px', md: '106px' }}
      borderRadius="lg"
    >
      <Heading size="2xl" color={themeMarketplace.textColor}>
        {title.substring(0, 1).toUpperCase()}
      </Heading>
    </Flex>
  );
}

export default DefaultImageProductList;
