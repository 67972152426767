import { Button, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { MdChevronLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function QuestionLibraryHeader() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex as="header" align="center" bg="#202123" py={3} position="relative">
      <Button
        as={Link}
        to="/home"
        leftIcon={<MdChevronLeft size="1.5rem" />}
        color="white"
        colorScheme="black"
        title="Voltar para a página inicial"
        fontSize="sm"
      >
        {isMobile ? '' : 'Voltar'}
      </Button>

      <Heading
        as="h1"
        fontSize={{ base: 'md', xl: 'xl' }}
        fontWeight="bold"
        color="white"
        marginLeft="auto"
        marginRight="auto"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
      >
        Biblioteca de Questões
      </Heading>
    </Flex>
  );
}
